<template>
  <div class="mm-login-button">
    <MButton
      v-if="!isUserLoggedIn"
      :size="MM.EButtonSizes.Medium"
      :type="MM.EButtonTypes.Text"
      :theme="btnTheme"
      :icon-right="srcIcon"
      @click="onShowLoginModal"
    >
      Войти
    </MButton>

    <div v-else>
      <AccountPopup>
        <MButton
          :size="MM.EButtonSizes.Medium"
          :type="MM.EButtonTypes.Text"
          :theme="btnTheme"
          :icon-right="srcIcon"
        >
          {{ userName }}
        </MButton>
        <div
          v-if="backNotificationService?.unreadCount?.value"
          class="mm-login-button__indicator"
        />
      </AccountPopup>
    </div>

    <LazyNotificationsDrawer v-if="isUserLoggedIn" />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from 'store/user.store';
import { BackNotificationService } from 'services/notification.service';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { AuthManagerService } from 'services/auth/authManager.service';
import { getFullNameAbbreviation } from 'shared/utils/fullNameAbbreviation.util';
import AccountPopup from './AccountPopup.vue';
import Loader from 'shared/utils/loaderHelper.util';
import { MM } from '~/types';
import { useHeaderStore } from 'store/Header.store';

const headerStore = useHeaderStore();

const props = defineProps<{
  isErrorPage?: boolean;
  inMobileMenu?: boolean;
}>();

const btnTheme = computed(() => {
  if (props.inMobileMenu) {
    return MM.EButtonThemes.Primary;
  } else {
    return headerStore.theme.isDark ? MM.EButtonThemes.White : MM.EButtonThemes.TextMain;
  }
});

const userStore = useUserStore();
const { isUserLoggedIn, userInfo } = storeToRefs(userStore);
const backNotificationService = inject<BackNotificationService>(BackNotificationService.getServiceName());

const loaderShowModal = Loader.getReactiveInstance();

const userName = computed(() =>
  getFullNameAbbreviation(userInfo?.value?.lastName, userInfo?.value?.name, userInfo?.value?.patronymic, userInfo?.value?.email),
);

const srcIcon = computed((): MM.EIconPath => (isUserLoggedIn.value ? MM.EIconPath.NavigationLkSvg : MM.EIconPath.NavigationLogin20pxSvg));

const { showLoginModal } = useAuthFnHelper();

async function onShowLoginModal() {
  try {
    loaderShowModal.activate();
    await showLoginModal(loginModalCallback, undefined, !props.isErrorPage, false);
  } finally {
    loaderShowModal.deactivate();
  }
}

async function loginModalCallback(): Promise<void> {
  await nextTick(async () => await AuthManagerService.checkSupplierRedirect());
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-login-button {
  position: relative;

  // :deep(.MButton) {
  //   .MButton__text {
  //     // transition: 0.2s;
  //   }
  // }

  &__indicator {
    z-index: 3;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $notification-indicator-bg;
    border-radius: 12px;
    right: -3px;
    top: -6px;
  }

  :deep(.mm-account) {
    .popper {
      background-color: transparent;
      padding: 0;
    }
  }
}
</style>
